import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginPage"),
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: () => import("@/views/LogoutPage")
  },
  {
    path: "/registration",
    name: "",
    component: () => import("@/views/registration/layout/RegistrationBase"),
    meta: {
      layout: "registration-base",
    },
    children: [
      {
        path: "",
        name: "registration",
        component: () => import('@/views/registration/pages/SignUpPage'),
      },
        // WooCommerce Installation Workflow
      {
        path: "woocommerce/create-account",
        name: "registration.woocommerce.create-account",
        component: () => import('@/views/registration/pages/woocommerce/CreateAccount')
      },
      {
        path: "woocommerce/select-store-type",
        name: "registration.woocommerce.select-store-type",
        component: () => import('@/views/registration/pages/woocommerce/SelectStoreType'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "woocommerce/verify-store-url",
        name: "registration.woocommerce.verify-store-url",
        component: () => import('@/views/registration/pages/woocommerce/VerifyStoreURL'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "woocommerce/select-plan",
        name: "registration.woocommerce.select-plan",
        component: () => import('@/views/plan/pages/PlanSelection'),
        meta: {
          requiresAuth: true,
        },
      }
    ]
  },
  {
    path: "/shopify",
    name: "",
    component: () => import("@/views/registration/layout/RegistrationBase"),
    children: [
      {
        path: "",
        name: "shopify",
        component: () => import('@/views/registration/pages/shopify/ShopifyEntry.vue'),
      },
      {
        path: "/shopify/select-store-type",
        name: "shopify.select-store-type",
        component: () => import('@/views/registration/pages/shopify/SelectStoreType'),
      },
      {
        path: "/shopify/select-plan",
        name: "shopify.select-plan",
        component: () => import('@/views/plan/pages/PlanSelection'),
      },
    ]
  },
  {
    path: "/shops",
    name: "ShopManagementPage",
    component: () => import("@/views/shop/pages/ShopManagementPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout",
    },
  },
  {
    path: "/dashboard",
    name: "DashboardPage",
    component: () => import("@/views/general/pages/DashboardPage"),
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout",
    }
  },
  {
    path: "/products",
    name: "ProductManagementPage",
    component: () => import("@/views/product/pages/ProductManagementPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout",
    },
  },
  {
    path: "/products/sync-configs",
    name: "ProductConfigurationsPage",
    component: () => import("@/views/product/pages/ProductConfigurationsPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "slid-bar-layout",
    },
  },
  {
    path: "/orders",
    name: "OrderManagementPage",
    component: () => import("@/views/order/pages/OrderManagementPage"),
    props: true,
    meta: {
      requiresAuth: true,
      layout: "hybrid-layout",
    },
    children: [
      // {
      //   path: "/push-configs",
      //   name: "PushOrderSettingsPage",
      //   component: () => import('@/views/order/pages/PushOrderSettingsPage'),
      // },
    ],
  },
  {
    path: "/orders/push-configs",
    name: "PushOrderSettingsPage",
    component: () => import('@/views/order/pages/PushOrderSettingsPage'),
    meta: {
      requiresAuth: true,
      layout: "hybrid-layout",
    },
  },
  // Hybrid Routes
  {
    path: "/hybrid/shopify/v1/order-module-access",
    name: "HybridOrderModuleAccess",
    component: () => import("@/views/order/pages/HybridOrderModuleAccess"),
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      requiresAuth: false,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  const isAuthenticated  = store.state.auth.isAuthenticated;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: "LoginPage",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
