export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const LOGIN_SHOPIFY = "loginShopify";
export const SAVE_SHOPIFY_TOKEN = "saveShopifyToken";
export const REGISTER = "register";
export const LOGOUT = "logout";
export const REMOVE_ERRORS = "removeErrors";

//shop actions
export const ASSIGN_SHOP_TYPE = "assignShopType";
export const REGISTER_SHOP_TYPE = "registerShopType";
export const REGISTER_SHOP = "registerShop";
export const LOAD_CONNECTED_SHOPS = "loadConnectedShops";

// Product Module Actions
export const ADD_PRODUCT_ACTION_STATUS = "addProductActionStatus";
export const LOAD_PRODUCTS_BY_SHOP_ID = "loadProductsByShopId";

// Processing Queue Module
export const ADD_ACTION = "addAction";
export const GET_NEXT_ACTION = "getNextAction";

// Notification Module
export const ADD_NOTIFICATION = "addNotification";
export const DESTROY_NOTIFICATION = "destroyNotification";
export const CLEAN_LIST = "cleanList";
export const DESTROY_NOTIFICATION_BY_ID = "destroyNotificationById";

// Product Sync Configs Module
export const RESET_CURRENT_CONFIGS = "resetCurrentConfigs";
export const FORMAT_AND_SET_CURRENT_CONFIGS = "formatAndSetCurrentConfigs";
export const LOAD_CURRENT_CONFIGURATIONS = "loadCurrentConfigurations";
export const UPDATE_CURRENT_CONFIGURATIONS = "updateCurrentConfigurations";

//Orders
export const FETCH_ORDERS = "fetchOrders";
export const FETCH_CURRENT_ORDER = "fetchCurrentOrder";
export const HIDE_SINGLE_ORDER = "hideSingleOrder";
export const PUSH_ORDER_TO_TARGET_STORE = "pushOrderToTargetStore";
export const AUTO_PUSH_TOGGLE = "autoPushToggle";
export const FETCH_PUSH_SETTINGS = "fetchPushSettings";
export const LAUNCH_STORE_ORDER_PUSHING_STATUS = "launchStoreOrderPushingStatus";
export const FETCH_ORDERS_LIST = "fetchOrdersList";
