import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "@/App";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import ApiService from "@/common/api/base.service";
import { CHECK_AUTH } from "@/store/actions.type";
import VueMoment from "vue-moment";

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));

import DefaultLayout from "@/views/layouts/DefaultLayout";
import SlidBarLayout from "@/views/layouts/SlidBarLayout";
import RegistrationBase from '@/views/registration/layout/RegistrationBase';
import HybridLayout from "@/views/layouts/HybridLayout";

Vue.component("default-layout", DefaultLayout);
Vue.component("slid-bar-layout", SlidBarLayout);
Vue.component("hybrid-layout", HybridLayout);
Vue.component("registration-base", RegistrationBase);

// Initiate the API Service in here
ApiService.init();
Vue.use(Vuelidate);
Vue.use(VueMoment);

// Use Promise race to make sure
// If Check auth get to long than 500 ms
// Generate Vue instance
// Just avoid blank page
Promise.race([
  store.dispatch(`auth/${CHECK_AUTH}`),
  new Promise((resolve) => setTimeout(resolve, 500)),
]).then(() => {
  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
