<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view></router-view>
      </component>
    </v-main>
  </v-app>
</template>

<script>
const default_layout = "default-layout";

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout);
    }
  },
};
</script>
<style>
@font-face {
  font-family: "Space Grotesk";
  src: url('./assets/fonts/SpaceGrotesk-1.1.6/ttf/SpaceGrotesk-Light.ttf') format("truetype");
}
</style>
