import ApiService from "@/common/api/base.service";

/**
 * Products related API to the backend
 */
export default {
    loadProductsByShopId(sId, dId, searchStr, filters) {
        let params = {
            source_store_id: sId,
            destination_store_id: dId,
            search_str: searchStr,
            filters: filters,
        };
        let url = "products?" + "source_store_id=" + params.source_store_id + "&" + "destination_store_id=" + params.destination_store_id;
        if (searchStr != null) {
            url += "&search_str=" + params.search_str;
        }
        if (filters != null && typeof (filters) === "object" && filters.length > 0) {
            filters.forEach(filter => {
                url += "&filters[]=" + filter;
            });
        }
            
        return ApiService.get(url);
    },
    loadProductOnShop(shopId, externalProductId) {
        return ApiService.get('products/search-by-id?store_id='+shopId+'&product_id='+externalProductId);
    },
    importProduct(connectionId, sourceProductId) {
        return ApiService.post('products/import-now', {
            connection_id: connectionId,
            source_product_id: sourceProductId,
        });
    },
    bulkProductsImport(connectionId, sourceProductIds) {
        return ApiService.post('products/import-now', {
            connection_id: connectionId,
            source_product_ids: sourceProductIds,
        });
    },
    unsyncProduct(mapperId, keepingProducts) {
        return ApiService.post('products/unsync', {
            mapper_ids: [ mapperId ],
            keeping_products: keepingProducts,
        });
    },
    searchProduct(shopId, searchStr) {
        return ApiService.get('products/search-store-products?store_id='+shopId+'&search_str='+searchStr);
    },
    syncAllProducts(connectionId) {
        return ApiService.post('products/bulk-sync-all', {
            connection_id: connectionId,
        });
    },
    mapProducts(sourceShopId, destinationShopId, sourceProductId, destinationProductId) {
        return ApiService.post('products/products-mapping', {
            source_store_id: sourceShopId,
            destination_store_id: destinationShopId,
            source_product_id: sourceProductId,
            destination_product_id: destinationProductId,
        })
    }
};