import ApiService from "@/common/api/base.service";

export default {
    getOrders(storeId, searchStr, sortBy, pageNumber){
        return ApiService.get(
          '/stores/'+storeId+'/orders', `?page=${pageNumber}&searchStr=${searchStr}&sortBy=${sortBy}`
        );
    },
    getCurrentOrder(storeId, orderId){
        return ApiService.get(
          '/stores/'+storeId+'/orders/'+orderId
        );
    },
    pushOrderToTargetStore(currentStoreId, currentOrderId, targetStoreId, shippingCost){
        return ApiService.post(
            '/stores/' + currentStoreId + '/orders/' + currentOrderId + '/push', {
                target_store_id: targetStoreId,
                shipping_cost: shippingCost
            }
        );
    },
    autoPushToggle(storeId) {
        return ApiService.post('/stores/' + storeId + '/orders/auto-push');
    },
    fetchPushSetting(storeId) {
        return ApiService.post('/stores/' + storeId + '/orders/push-settings');
    },
    saveSinglePushSetting(storeId, configId, data) {
        return ApiService.post('/stores/' + storeId + '/orders/push-settings/' + configId, {
            data: data,
        })
    }
}