<template>
<div class="processing-station-wrapper">
    <v-card v-if="currentTask != null && displayDialog"
        class="mb-3 task-container"
        outlined
        width="300"
        height="72"
    >
        <v-card-text v-if="currentTask.action === 'product_import'"
            class="content"
        >
            Importing product {{ currentTask.actionParam.product.title }}...
        </v-card-text>
        <v-card-text v-else-if="currentTask.action === 'product_unsync'"
            class="content"
        >
            Unsyncing product {{ currentTask.actionParam.product.title }}, while 
            <span v-if="currentTask.actionParam.keepProduct">KEEPING product in your store</span>
            <span v-else>DELETING product in your store</span>...
        </v-card-text>
        <v-icon
            class="float-right"
            @click="displayDialog = false"
        >close</v-icon>
    </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import ProductApiService from '@/common/api/product.service';
import { SET_CURRENT_TASK, SET_PROCESSING_QUEUE } from "@/store/mutations.type";
import { GET_NEXT_ACTION, ADD_NOTIFICATION } from "@/store/actions.type";

export default {
    name: "ProcessingStation",
    data: function() {
      return {
        displayDialog: true,
      };
    },
    computed: {
        ...mapState('processQueue', ['processingQueue', 'currentTask'])
    },
    watch: {
      processingQueue(list){
        if (list.length > 0) {
          // click start the first run the get next action task to currentTask, when it is available
          if (this.currentTask === null) {
            this.$store.dispatch(`processQueue/${GET_NEXT_ACTION}`);
          }
        }
      },
      currentTask(ct) {
        if (ct === null) {
            if (this.processingQueue.length > 0) {
                this.$store.dispatch(`processQueue/${GET_NEXT_ACTION}`);
            } else {
                return;
            }
        } else {
            let task = this.currentTask;
            switch(task.action) {
                case "product_import":
                this.importNextProduct(task.actionParam.connection_id, task.actionParam.product, task.actionParam.productStatusKey);
                break;
                case "product_unsync":
                this.unsyncNextProduct(task.actionParam.product, task.actionParam.keepProduct, task.actionParam.productStatusKey);
                break;
            }
        }
      }
    },
    methods: {
        unsyncNextProduct(product, keepProduct, statusKey) {
            this.displayDialog = true;
            new Promise((resolve) => {
                ProductApiService.unsyncProduct(product.mapper_id, keepProduct)
                    .then(({ data }) => {
                        if (data.success) {
                            let currenTasks = this.processingQueue;
                            // update it in one goes.
                            let newQueue = currenTasks.filter((thisTask) => {
                                return (
                                thisTask.product.id != product.id &&
                                thisTask.action === 'product_unsync'
                                );
                            });
                            this.$store.commit(`processQueue/${SET_PROCESSING_QUEUE}`, newQueue)
                            // release current task and allow next one to be place in the cuurentTask
                            this.$store.commit(`processQueue/${SET_CURRENT_TASK}`, null);
                            this.$store.dispatch(`product/removeProductActionStatusByKey`, {
                                identifier: statusKey
                            });
                            resolve();
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.commit(`processQueue/${SET_CURRENT_TASK}`, null);
                        this.$store.dispatch(`product/updateProductActionStatusByKey`, {
                            identifier: statusKey,
                            statusValue: 'error',
                            message: "Unsync Product Failed."
                        });
                    });
            });
        },
      importNextProduct(connectionId, product, statusKey) {
            this.displayDialog = true;
            new Promise((resolve) => {
                ProductApiService.importProduct(connectionId, product.external_product_id)
                    .then(({ data }) => {
                        if (data.success) {
                        let currenTasks = this.processingQueue;
                        // update it in one goes.
                        let newQueue = currenTasks.filter((thisTask) => {
                            return (
                            thisTask.product.id != product.id &&
                            thisTask.action === 'product_import'
                            );
                        });
                        this.$store.commit(`processQueue/${SET_PROCESSING_QUEUE}`, newQueue)
                        this.$store.commit(`processQueue/${SET_CURRENT_TASK}`, null);
                        this.$store.dispatch(`product/removeProductActionStatusByKey`, {
                            identifier: statusKey
                        });
                        resolve();
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                            notification: {
                                id: 'N' + (Math.floor(Math.random() * 100000000)),
                                position: "top-left",
                                type: "error",
                                body: "Import Product ["+product.title+"] Failed!",
                                state: 0,
                                length: 8000, // seconds
                                data: null,
                            },
                        });
                        this.$store.commit(`processQueue/${SET_CURRENT_TASK}`, null);
                        this.$store.dispatch(`product/updateProductActionStatusByKey`, {
                            identifier: statusKey,
                            statusValue: 'error',
                            message: "Import Product Failed."
                        });
                    });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.processing-station-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    .task-container {
        border-radius: 3px;
        margin-left: 5px;
        border-left: 10px solid #FCB058;
        overflow: hidden;
        .content {
            width: 260px;
            float: left;
        }
        .v-icon {
            line-height: 72px;
        }
    }
}
</style>