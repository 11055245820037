import ShopApiService from "@/common/api/shop.service";
import $ from "jquery";
import {

} from "@/store/actions.type";
import {

} from "@/store/mutations.type";

const initialState = {
    currentActivePlan: null,
    selectedNewPlan: null,
    availablePlansList: [],
    storeId: null,
    storeName: null,
    syncioKey: null,
    storeType: null,
    monthlyOrderCount: null,
};

export const state = { ...initialState };

const getters = {

};

const actions = {
    init({dispatch}) {
        dispatch('loadAvailablePlans');
        dispatch('getCurrentPlan', true);
    },
    getCurrentPlan({state}, copyToNewPlan) {
        ShopApiService.getCurrentPlans()
            .then((response) => {
                response = response.data;
                if (response.success) {
                    state.currentActivePlan = response.plan;
                    if (copyToNewPlan) {
                        state.selectedNewPlan = Object.assign({}, state.currentActivePlan.syncio_plan);
                        state.selectedNewPlan.id = state.selectedNewPlan.plan_id;
                        state.currentActivePlan.name = state.currentActivePlan.name.replace(' Plus', '');
                        if (!state.currentActivePlan.syncio_plan.active) {
                            state.currentActivePlan.name += ' (legacy)'
                        }
                        state.selectedNewPlan.name = state.currentActivePlan.name.replace(' Plus', '');
                        state.selectedNewPlan.price_per_month = state.currentActivePlan.syncio_plan.price_per_month;
                        state.selectedNewPlan.addon = [];
                        state.selectedNewPlan.price_per_month = state.currentActivePlan.price;
                        $.each(state.currentActivePlan.addons, (key, addon) => {
                            addon.selected = true;
                            state.selectedNewPlan.addon.push(addon);
                        });
                        // use this line here to convert the addons back to array in case it only have one item which show as an object.
                        state.currentActivePlan.addons = Object.assign([], state.selectedNewPlan.addon);
                    }
                } else {
                    alert("Error: Can't load the current plan data!")
                }
            })
            .catch((error) => {
                console.log(error);
            })
    },
    loadAvailablePlans({ state }) {
        ShopApiService.getAllPlans()
            .then((response) => {
                response = response.data;
                if(response.success) {
                    state.availablePlansList = response.plans;
                } else {
                    alert('Plans loading error!')
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
};

const mutations = {
    SET_SELECTED_NEW_PLAN_BY_ID (state, planId) {
        if (state.currentActivePlan != null) {
            if (state.currentActivePlan.id === planId) {
                return;
            }
        }
        let newPlan = null;
        $.each(state.availablePlansList, (key, plan) => {
            if (plan.id === planId) {
                newPlan = plan;
            } else {
                return;
            }
            if (newPlan != null && state.selectedNewPlan != null) {
                $.each(state.selectedNewPlan.addon, (index, addon) => {
                    $.each(newPlan.addon, (index, planAddon) => {
                        if (planAddon.module_id === addon.module_id) {
                            planAddon.selected = addon.selected;
                            return;
                        }
                    });
                });
            } else if (newPlan != null && state.currentActivePlan != null) {
                newPlan.addon = Object.assign({}, state.currentActivePlan.addons);
            }
        });

        // If we can't find any active plan for this plan ID then we need to check is it related to current plan
        // if so we set the current plan to newPlan
        if (newPlan === null && planId === state.currentActivePlan.syncio_plan.plan_id) {
            newPlan = Object.assign({}, {
                "id": state.currentActivePlan.syncio_plan.plan_id,
                "active": state.currentActivePlan.syncio_plan.active,
                "description": state.currentActivePlan.syncio_plan.description,
                "limit": state.currentActivePlan.syncio_plan.limit,
                "price_per_month": state.currentActivePlan.syncio_plan.price_per_month,
                "name": state.currentActivePlan.syncio_plan.name,
            });
        }

        state.selectedNewPlan = Object.assign({}, newPlan);
    },
    SET_SELECTED_NEW_PLAN (state, plan) {
        state.selectedNewPlan = null;
        state.selectedNewPlan = plan;
    },
};

const namespaced = true

export default {
    namespaced,
    state,
    actions,
    mutations,
    getters
};
