import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth';
import shop from '@/store/modules/shop';
import product from '@/store/modules/product';
import plan from '@/store/modules/plan'
import processQueue from '@/store/modules/process-queue';
import notification from '@/store/modules/notification';
import configs from '@/store/modules/configs';
import orders from '@/store/modules/orders';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        plan,
        auth,
        shop,
        product,
        processQueue,
        notification,
        configs,
        orders
    },
})