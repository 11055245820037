<template>
  <v-app>
      <v-container>
        <router-view></router-view>
      </v-container>
  </v-app>
</template>
<script>
export default {
    name: "DefaultLayout"
}
</script>
<style scoped>

</style>