<template>
<div class="notification-component-wrapper" :class="position">
    <transition-group name="list">
    <v-card v-for="notification in items" v-bind:key="notification.id"
        class="syncio-notification mb-1 list-item" :class="notification.type+'-border'"
        outlined
    >
        <v-card-text
        >
            <div class="d-flex">
              <v-icon v-if="notification.type === 'info'"
                      class="info-icon"
                      color="info"
              >
                info
              </v-icon>
              <v-icon v-else-if="notification.type === 'warning'"
                      class="warning-icon"
                      color="warning"
              >
                warning
              </v-icon>
              <v-icon v-else-if="notification.type === 'error'"
                      class="error-icon"
                      color="error"
              >
                error
              </v-icon>
              <v-icon v-if="notification.type === 'success'"
                      class="success-icon"
                      color="success"
              >
                check_circle
              </v-icon>
              <div class="ml-5">
                <div class="title" v-if="notification.type === 'success'" v-text="'Success'"></div>
                <div class="title" v-if="notification.type === 'error'" v-text="'Error'"></div>
                <div class="body" v-text="notification.body"></div>
              </div>
              <v-spacer></v-spacer>
              <v-icon
                  class="float-right"
                  @click="closeThisNotification(notification.id)"
              >close</v-icon>
            </div>
        </v-card-text>
    </v-card>
    </transition-group>
</div>
</template>
<script>
import { mapState } from "vuex";
import { DESTROY_NOTIFICATION_BY_ID } from "@/store/actions.type";

export default {
    name: "NotificationComponent",
    props: [ "position"],
    data: function() {
        return {

        };
    },
    computed: {
        ...mapState('notification', [ "notificationsList" ]),
        items () {
            return this.notificationsList.filter((v) => {
               return (
                    v.position === this.position
                );
            });
        }
    },
    methods: {
        closeThisNotification(notificationId) {
            this.$store.dispatch(`notification/${DESTROY_NOTIFICATION_BY_ID}`, { id: notificationId });
        }
    }
}
</script>
<style lang="scss" scoped>
.notification-component-wrapper {
    position: fixed;
    &.title{
      font-size: 14px;
      font-weight: bold;
    }
    &.body{
     font-size: 12px;
    }
    &.top-left {
        top: 15px;
        left: 15px;
        width: 100%;
        max-width: 500px;
        z-index: 999;
    }
    &.top-right {
        top: 15px;
        right: 15px;
        width: 100%;
        max-width: 500px;
        z-index: 999;
    }
    &.bottom-right {
        bottom: 15px;
        right: 15px;
        width: 100%;
        max-width: 500px;
        z-index: 999;
    }
    &.bottom-left {
      bottom: 15px;
      left: 15px;
      width: 100%;
      max-width: 500px;
      z-index: 999;
    }
    .syncio-notification {
        overflow: hidden;
        border-radius: 3px;
        &.info-border {
            border-color: #2196f3;
            border-left: 8px solid #2196f3;
        }
      &.success-border {
        border-color:   #29a329;
        border-left: 8px solid #29a329;
      }
        &.warning-border {
            border-color: #fb8c00;
            border-left: 8px solid #fb8c00;
        }
        &.error-border {
            border-color: #ff5252;
            border-left: 8px solid #ff5252;
        }
    }
}

.list-enter-active, .list-leave-active {
  transition: all 0.5s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>