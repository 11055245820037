

export const SET_AUTH = "setAuth";
export const RESET_TOKEN = "resetToken";
export const SET_USERS = "setUsers";
export const SET_ERROR = "setError";
export const SET_CUSTOM_ERROR = "setCustomError";
export const UNSET_ERROR = "unsetError";
export const PURGE_AUTH = "logOut";
export const SET_LOADING = "setLoading";
export const UNSET_LOADING = "unsetLoading";
export const SET_CURRENT_PAGE = "setCurrentPage";


//shops mutations

export const SET_SHOP_TYPE = "setShopType";
export const SET_NEW_SHOP = "setNewShop";
export const SET_NEW_SHOP_TYPE = "setNewShopType";
export const SET_CURRENT_SHOP = "setCurrentShop";
export const SET_CONNECTED_SHOPS = "setConnectedShops";
export const SET_NEWLY_CONNECTED_SHOP = "setNewlyConnectedShop";
export const SET_IS_LOADING_CONNECTED_SHOPS = "setIsLoadingConnectedShops";
export const SET_IS_CONNECTING_SHOP = "setIsConnectingShop";

// Product Module Mutations
export const SET_CURRENT_SHOP_PRODUCTS = "setCurrentShopProducts";
export const SET_IS_LOADING_PRODUCTS_BY_SHOP = "setIsLoadingProductsByShop"; //to be change
export const SET_CURRENT_VIEW_SHOP_PRODUCT = "setCurrentViewShopProduct";
export const SET_SHOW_PRODUCT_DETAIL = "setShowProductDetail";
export const SET_SHOW_PRODUCT_MAPPER = "setShowProductMapper";
export const SET_PRODUCT_PAGE_ENTRY_SHOP_ID = "setProductPageEntryShopId";
export const SET_SELECTED_PRODUCT_SHOP_ID = "setSelectedProductShopId";
export const SET_CURRENT_PARTNER_SHOP = "setCurrentPartnerShop";

// Processing Queue Module
export const SET_PROCESSING_QUEUE = "setProcessingQueue";
export const SET_CURRENT_TASK = "setCurrentTask";

// Product Sync Module Mutations
export const SET_CURRENT_CONFIGS = "setCurrentConfigs";

//Orders
export const SET_ORDERS = "setOrders";
export const UNSET_ORDERS_LIST = "unsetOrdersList";
export const SET_ORDERS_LIST = "setOrdersList";
export const SET_CURRENT_ORDER = "setCurrentOrder";
export const UNSET_CURRENT_ORDER = "unsetCurrentOrder";
export const SET_SHOW_SINGLE_ORDER = "setShowSingleOrder";
export const UNSET_SHOW_SINGLE_ORDER = "unsetShowSingleOrder";
export const SET_PUSH_SETTINGS = "setPushSettings";
export const SET_CURRENT_LOADING = "setCurrentOrderLoading";
export const UNSET_CURRENT_LOADING = "unsetCurrentOrderLoading";
export const SET_STORE_ORDER_PUSHING_STATUS = "setStoreOrderPushingStatus";
export const APPEND_ORDERS_LIST = "appendOrdersList";
export const UPDATE_ORDER_PUSH_STATUS_ORDER_LIST = "updateOrderPushStatusOrderList";
