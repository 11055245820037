
import ShopApiService from "@/common/api/shop.service";
import {
    SET_CURRENT_SHOP, SET_NEW_SHOP_TYPE, SET_SHOP_TYPE, SET_IS_CONNECTING_SHOP,
    SET_CONNECTED_SHOPS, SET_NEWLY_CONNECTED_SHOP, SET_IS_LOADING_CONNECTED_SHOPS, SET_NEW_SHOP

} from "@/store/mutations.type";

import {ASSIGN_SHOP_TYPE, REGISTER_SHOP, REGISTER_SHOP_TYPE, ADD_NOTIFICATION, LOAD_CONNECTED_SHOPS} from "@/store/actions.type";

const mutations = {
    [SET_CURRENT_SHOP](state, shop) {
        state.currentShop = shop;
    },
    SET_IS_LOADING(state, value) {
        state.isLoading = value;
    },
    [SET_IS_CONNECTING_SHOP](state, value) {
        state.isConnectingShop = value;
    },
    [SET_CONNECTED_SHOPS](state, shops) {
        state.connectedShops = shops;
    },
    [SET_SHOP_TYPE](state, type) {
        state.currentShop.type = type;
    },
    [SET_NEW_SHOP_TYPE](state, type) {
        state.newShop.type = type;
    },
    [SET_NEWLY_CONNECTED_SHOP](state, shop) {
        state.newlyConnectedShop = shop;
    },
    [SET_IS_LOADING_CONNECTED_SHOPS](state, value) {
        state.isLoadingConnectedShops = value;
    },
    [SET_NEW_SHOP](state,shop){
        state.newShop = shop;
    },
};

const actions = {
    init(context) {
        if (context.state.currentShop === null) {
            context.dispatch('loadCurrentShop');
            context.commit('SET_IS_LOADING', true);
            setTimeout(() => {
                context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
            }, 5000);
        } else {
            context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
        }
    },
    [ASSIGN_SHOP_TYPE](context, { type }) {
        context.commit(SET_NEW_SHOP_TYPE, type);
    },
    [REGISTER_SHOP_TYPE](context, { store_id, type }) {
        return new Promise((resolve) => {
            ShopApiService.setShopType(store_id, type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    console.log(error);
                })
        });
    },
    [REGISTER_SHOP](context, params) {
        context.commit('SET_IS_LOADING', true);
        return new Promise((resolve) => {
            ShopApiService.createStore(params)
                .then(({ data }) => {
                    if (data.success) {
                        context.commit(SET_CURRENT_SHOP, data.store);
                    }
                    resolve(data);
                })
                .catch((error) => {
                    console.log(error);
                })
        });
    },
    [LOAD_CONNECTED_SHOPS]({ state, commit }, { searchStr }) {
        if (state.currentShop === null) {
            return;
        }
        commit(SET_IS_LOADING_CONNECTED_SHOPS, true);
        return new Promise((resolve) => {
            commit('SET_IS_LOADING', true);
            let shopId = state.currentShop.id
            ShopApiService.getShopConnections(shopId, searchStr)
                .then(({ data }) => {
                    if (data.success) {
                        commit(SET_CONNECTED_SHOPS, data.stores);
                    }
                    commit('SET_IS_LOADING', false);
                    commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    commit('SET_IS_LOADING', false);
                    commit(SET_IS_LOADING_CONNECTED_SHOPS, false);
                })
        });
    },
    loadCurrentShop({ commit }) {
        // TODO: might want to remove the generic isLoading value commit later.
        commit('SET_IS_LOADING', true);
        return new Promise((resolve) => {
            ShopApiService.getAccountStores(1, null)
                .then(({ data }) => {
                    if (data.success) {
                        // Currently we only allow 1 account per stores
                        commit(SET_CURRENT_SHOP, data.stores[0]);
                    }
                    commit('SET_IS_LOADING', false);
                    resolve(data);
                })
                .catch((error) => {
                    console.log(error);
                    commit('SET_IS_LOADING', false);
                });
        });
    },
    loadNewShop(context) {
        context.commit("SET_IS_LOADING", true)
        return new Promise((resolve) => {
            ShopApiService.getAccountStores(1, null)
                .then(({ data }) => {
                    if (data.success) {
                        // Currently we only allow 1 account per stores
                        context.commit(SET_NEW_SHOP, data.stores[0]);
                        context.commit("SET_IS_LOADING", false)
                        resolve(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    context.commit("SET_IS_LOADING", false)
                });
            });
        },
    sendEmailInvite(context, { toEmail, shopId }) {
        return new Promise((resolve) => {
            context.commit('SET_IS_LOADING', true);
            ShopApiService.inviteShop(toEmail, shopId)
                .then(({ data }) => {
                    if (data.success) {
                        alert('email send');
                    } else {
                        alert('email not send')
                    }
                    context.commit('SET_IS_LOADING', false);
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    context.commit('SET_IS_LOADING', false);
                });
        });
    },
    connectStoreWithKey(context, { shopId, key }) {
        context.commit(SET_IS_CONNECTING_SHOP, true);
        context.commit(SET_NEWLY_CONNECTED_SHOP, null);
        return new Promise((resolve) => {
            ShopApiService.connectShop(shopId, key)
                .then(({ data }) => {
                    if (data.success) {
                        context.commit(SET_NEWLY_CONNECTED_SHOP, data.target_store);
                        context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
                    } else {
                        alert('shop connection failed')
                        context.commit(SET_IS_CONNECTING_SHOP, false);
                    }
                    resolve();
                })
                .catch(({ data }) => {
                    console.log(data.errors);
                    let errorMsg = "Failed to connect store with " + key;
                    if (typeof (data.errors) != 'undefined' && typeof(data.errors.target_store_identifier) != 'undefined') {
                        errorMsg = data.errors.target_store_identifier[0];
                    }
                    context.dispatch(`notification/${ADD_NOTIFICATION}`, {
                        notification: {
                            id: 'N' + (Math.floor(Math.random() * 100000000)),
                            position: "top-left",
                            type: "error",
                            body: errorMsg,
                            state: 0,
                            length: 8000, // seconds
                            data: null,
                        },
                    }, {root:true});
                    context.commit(SET_IS_CONNECTING_SHOP, false);
                });
        });
    },
    disconnectShop(context, { connectionId, keepProduct }) {
        context.commit('SET_IS_LOADING', true);
        return new Promise((resolve) => {
            ShopApiService.disconnectShop(connectionId, keepProduct)
                .then(({ data }) => {
                    if (data.success) {
                        context.commit('SET_IS_LOADING', false);
                        context.dispatch(LOAD_CONNECTED_SHOPS, { searchStr: null });
                    } else {
                        alert('shop disconnection failed')
                    }
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    context.commit('SET_IS_LOADING', false);
                });
        });
    }
};

const getters = {
    newShopType(state) {
        return state.newShop.type;
    },
    currentShopType(state) {
        return state.currentShop.type;
    },
    currentShop(state) {
        return state.currentShop;
    },
    newShop(state) {
        return state.newShop;
    }
};

export default {
    namespaced: true,
    state: {
        newShop:{},
        currentShop: null,
        connectedShops: [],
        isLoading: false,
        isConnectingShop: false,
        isLoadingConnectedShops: false,
        newlyConnectedShop: null,
    },
    mutations,
    actions,
    getters,
};