<template>
  <nav>
    <v-app-bar flat app style="background-color: #f8f8fa">
      <router-link to="/login">
        <v-img
            class="ml-12"
            src="@/assets/images/logo.svg"
            contain
            position="left"
            max-width="50px"
            max-height="65px"
        >
        </v-img>
      </router-link>
    </v-app-bar>
  </nav>
</template>

<script>

export default {
  name: "RegistrationNavBar",
  data () {
    return {
    }
  },
}
</script>

<style scoped>
.syncio-appbar-logo{
}
</style>