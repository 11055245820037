import ProductApiService from "@/common/api/product.service";
import {
    SET_CURRENT_SHOP_PRODUCTS, SET_IS_LOADING_PRODUCTS_BY_SHOP, SET_CURRENT_VIEW_SHOP_PRODUCT,
    SET_SHOW_PRODUCT_DETAIL, SET_SHOW_PRODUCT_MAPPER, SET_PRODUCT_PAGE_ENTRY_SHOP_ID,
    SET_SELECTED_PRODUCT_SHOP_ID, SET_CURRENT_PARTNER_SHOP
} from "@/store/mutations.type";
import {
    ADD_PRODUCT_ACTION_STATUS, LOAD_PRODUCTS_BY_SHOP_ID
} from "@/store/actions.type";

const state = {
    currentShopProducts: [],
    isLoadingProductsByShop: false,
    currentViewShopProduct: null,
    showProductDetail: false,
    showProductMapper: false,
    productPageEntryShopId: null,
    selectProductShopId: null,
    currentPartnerShop: null,
    productActionStatusMapper: [],
};

const mutations = {
    [SET_CURRENT_SHOP_PRODUCTS](state, products) {
        state.currentShopProducts = products;
    },
    [SET_IS_LOADING_PRODUCTS_BY_SHOP](state, value) {
        state.isLoadingProductsByShop = value;
    },
    [SET_CURRENT_VIEW_SHOP_PRODUCT](state, product) {
        state.currentViewShopProduct = product;
    },
    [SET_SHOW_PRODUCT_DETAIL](state, value) {
        state.showProductDetail = value;
    },
    [SET_SHOW_PRODUCT_MAPPER](state, value) {
        state.showProductMapper = value;
    },
    [SET_PRODUCT_PAGE_ENTRY_SHOP_ID](state, value) {
        state.productPageEntryShopId = value;
    },
    [SET_SELECTED_PRODUCT_SHOP_ID](state, id) {
        state.selectProductShopId = id;
    },
    [SET_CURRENT_PARTNER_SHOP](state, shop) {
        state.currentPartnerShop = shop;
    },
};

const actions = {
    [LOAD_PRODUCTS_BY_SHOP_ID]({ commit }, { sourceId, destinationId, searchStr, filters }) {
        new Promise((resolve) => {
            commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, true);
            ProductApiService.loadProductsByShopId(sourceId, destinationId, searchStr, filters)
                .then(({ data }) => {
                    if (data.success) {
                        commit(SET_CURRENT_SHOP_PRODUCTS, data.products);
                    }
                    resolve();
                    commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, false);
                })
                .catch((error) => {
                    console.log(error);
                    commit(SET_IS_LOADING_PRODUCTS_BY_SHOP, false);
                })
        });
    },
    [ADD_PRODUCT_ACTION_STATUS]({ state }, { connectionId, productId, statusValue, message }) {
        let identifier = connectionId + ':' + productId;
        let isDuplicated = false;
        state.productActionStatusMapper.forEach(($item) => {
            if ($item.key === identifier) {
                isDuplicated = true;
            }
        });
        if (!isDuplicated) {
            state.productActionStatusMapper.push({
                key: identifier,
                status: statusValue,
                message: message,
            });
        }
    },
    updateProductActionStatus({ state }, { connectionId, productId, statusValue, message }) {
        let identifier = connectionId + ':' + productId;
        state.productActionStatusMapper.forEach(($item) => {
            if ($item.key === identifier) {
                $item.status = statusValue;
                $item.message = message;
            }
        });
    },
    updateProductActionStatusByKey({ state }, { identifier, statusValue, message }) {
        state.productActionStatusMapper.forEach(($item) => {
            if ($item.key === identifier) {
                $item.status = statusValue;
                $item.message = message;
            }
        });
    },
    removeProductActionStatus({ state }, { connectionId, productId }) {
        let identifier = connectionId + ':' + productId;
        let removeIndex = null;
        state.productActionStatusMapper.forEach(($item, index) => {
            if ($item.key === identifier) {
                removeIndex = index;
            }
        });
        state.productActionStatusMapper.splice(removeIndex, 1);
    },
    removeProductActionStatusByKey({ state }, { identifier }) {
        let removeIndex = null;
        state.productActionStatusMapper.forEach(($item, index) => {
            if ($item.key === identifier) {
                removeIndex = index;
            }
        });
        state.productActionStatusMapper.splice(removeIndex, 1);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};