<template>
  <v-app class="grey lighten-4">
    <HybridNavbar/>
    <v-container class="mx4 mb-4">
      <router-view></router-view>
    </v-container>
    <processing-station></processing-station>

    <notification-component
        position="top-left"
    ></notification-component>

    <notification-component
        position="top-right"
    ></notification-component>

    <notification-component
        position="bottom-right"
    ></notification-component>

    <notification-component
        position="bottom-left"
    ></notification-component>
  </v-app>
</template>

<script>
import HybridNavbar from "@/views/components/HybridNavbar";
import ProcessingStation from "@/views/components/ProcessingStation";
import NotificationComponent from "@/views/components/NotificationComponent";

export default {
  name: "HybridLayout",
  components: {
    HybridNavbar,
    ProcessingStation,
    NotificationComponent,
  },
}
</script>

<style scoped>

</style>