<template>
  <nav>
    <v-app-bar
      app
      flat
      color="white"
      clipped-left
    >
      <v-toolbar-title
        class="pl-5"
      >
        <v-img
          height="40"
          width="115"
          src="@/assets/images/syncio_logo_full.svg"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn 
        icon
        class="mr-5"
      >
        <v-icon>notifications_none</v-icon>
      </v-btn>
      <div class="toolbar-account">
        <span class="avator mr-1">{{ shortName }}</span>
        <span class="">
          {{ user.name }}
        </span>
      </div>
    </v-app-bar>
    
    <!-- <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon> -->
    <v-navigation-drawer 
      app 
      v-model="drawer" 
      class="primary"
      clipped
    >
      <div v-for="link in links" :key="link.text">
        <!-- <v-item >
          <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon>
        </v-item> -->
        <v-list class="pa-0">
          <v-list-group no-action v-if="typeof(link.subLinks) != 'undefined' && link.subLinks.length > 0"> 
            <v-list-item slot="activator" :to="link.route" class="pl-0">
              <v-list-item-icon>
                <v-icon class="white--text">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item v-for="sublink in link.subLinks" :key="sublink.text" :to="sublink.route" class="sublink-item">
              <v-list-item-title class="white--text">{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item :to="link.route" v-else>
            <v-list-item-icon>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    
  </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Navbar",
  data () {
    return {
      drawer: true,
      links: [
        { icon: 'bar_chart', text: 'Dashboard', route: '/dashboard' },
        { icon: 'location_on', text: 'Stores', route: '/shops' },
        { icon: 'local_offer', text: 'Products', route: '#',
          subLinks: [
            { text: 'Product Management', route: '/products' },
            { text: 'Sync Configurations', route: '/products/sync-configs' },
          ],
        },
        { icon: 'move_to_inbox', text: 'Orders', route: '/orders' },
        { icon: 'settings', text: 'Settings', route: '/account-setting' },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    shortName() {
      if (this.user != null && typeof(this.user.name) != "undefined") {
        let nameArray = this.user.name.split(" ");
        let firstName = nameArray[0];
        let lastName = null;
        if (typeof(nameArray[1]) != "undefined") {
          lastName = nameArray[1];
        }
        let firstCharacter = firstName.split("")[0];
        let secondCharacter = null;
        if (lastName != null) {
          secondCharacter = lastName.split("")[0];
        } else {
          firstCharacter += firstName.split("")[1];
        }
        return firstCharacter + secondCharacter;
      }
      return "N/A";
    },
  },
}
</script>

<style lang="scss" scoped>
nav {
  .toolbar-account {
    margin-right: 25px;
    .avator {
      padding: 8px 9px;
      border-radius: 50px;
      background-color: #80D8FF;
      color: #fff;
    }
  }
  .sublink-item {
    border-left: 5px solid orange;
  }
  .v-list-item--link:before {
    background-color: unset;
  }
}
</style>