import Vue from "vue";
import axios from "axios";
import router from "@/router";
import store from '@/store/index';



import VueAxios from "vue-axios";
import {API_URL, VUE_APP_ID, VUE_APP_V1_BASE_URL} from "@/config";

import JwtService from "@/common/jwt.service";
import {ADD_NOTIFICATION} from "@/store/actions.type";

export default {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common["x-syncio-app-id"] = VUE_APP_ID;

    Vue.axios.interceptors.response.use(
        (response) => {
          if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
          } else {
            return Promise.reject(response);
          }
        },
        (error) => {
          if (error.response.status) {
            switch (error.response.status) {
              case 400:
                if (error.response.data.success === false){
                  let errors = error.response.data.errors;
                  errors.forEach(function (error) {
                      store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                        notification: {
                          id: 'N' + (Math.floor(Math.random() * 100000000)),
                          position: "top-left",
                          type: "error",
                          body: error,
                          state: 0,
                          length: 6000, // seconds
                          data: null,
                        },
                      });
                  })
                }
                break;
              case 401:
                alert("session expired");
                break;
              case 403:
                // router.replace({
                //   path: "/login",
                //   query: { redirect: router.currentRoute.fullPath },
                // });
                window.location.href=VUE_APP_V1_BASE_URL+"/retailers";
                break;
              case 502:
                setTimeout(() => {
                  router.replace({
                    path: "/login",
                    query: {
                      redirect: router.currentRoute.fullPath,
                    },
                  });
                }, 1000);
            }
            return Promise.reject(error.response);
          }
        }
    );
  },
  setHeader() {
    const token = JwtService.getToken();
    if (token) {
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    const subpath = slug ? `/${slug}` : ``;
    return Vue.axios.get(`${resource}` + subpath).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};