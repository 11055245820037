<template>
  <v-app>
      <RegistrationNavBar/>
      <v-container>
        <router-view></router-view>
      </v-container>
  </v-app>
</template>
<script>
import RegistrationNavBar from "@/views/registration/components/RegistrationNavBar";
export default {
  components: { RegistrationNavBar }
}

</script>
<style scoped>

</style>